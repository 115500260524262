
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  // margin-top: $gl-spacer-l;
  margin-top: 24px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  @include media(">=md") {
    // margin-top: $gl-spacer-2xl;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
  }
}

.text {
  font-size: 15px;
  line-height: 150%;
  color: #121212;
  margin: 8px 0;
  letter-spacing: -0.02em;

  @include media(">=md") {
    margin: initial;
    margin-right: auto;
    margin-left: 12px;
  }
}

.link {
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  font-weight: 500;
  color: #121212;
}

.arrow {
  margin-left: 4px;
}
