
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$spacing: 40px;

@include desktop {
  .container {

    .row {
      @include make-row-gap($spacing);

      .col {
        @include make-col-padding(math.div($spacing, 2));
      }
    }
  }

  .col:first-child {
    display: flex;
  }

}

