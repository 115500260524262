
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$variants: (
  question: (
    background: rgba(237, 246, 246, 0.8),
    icon:       #00be9f,
  ),
  answer: (
    background: linear-gradient(0deg, #fdf5ee, #fdf5ee),
    icon:       #ff9744,
  ),
  upload: (
    background: linear-gradient(0deg, #f1f4ff, #f1f4ff),
    icon:       #4b74ff,
  ),
);
$wrapper-bg:  linear-gradient(140.55deg, #FFFFFF 25.88%, rgba(255, 255, 255, 0.2) 92.01%);
$wrapper-shadow:  4px 8px 24px rgba(0, 0, 0, 0.08), inset -2px -2px 4px rgba(255, 255, 255, 0.3);

.item {
  @include button-reset();
  padding: $gl-spacer-s;
  border-radius: 16px;
  font-size: $gl-font-size-xs;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $clr-dark-font;
  width: 100%;

  &.column {
    display: block;

    .text {
      margin-top: $gl-spacer-xxs;
    }
  }
}

@each $variant, $opts in $variants {
  .#{'' + $variant} {
    background: map-get($opts, background);

    .icon {
      color: map-get($opts, icon);
      fill: map-get($opts, icon);
    }
  }
}

.icon-wrapper {
  @include size(48px);

  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  background: $wrapper-bg;
  box-shadow: $wrapper-shadow;
}

.text {
  flex: 1;
}

.subtext {
  display: none;
  font-size: 11px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.61);
  margin-top: 4px;
}

@include media("<md") {
  .subtext {
    display: block;
  }

  .mobile {
    display: block;
    font-size: $gl-font-size-xs;

    .text {
      margin-top: $gl-spacer-xs;
    }
  }
}
