
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.img {
  @include size(56px);
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: $gl-spacer-xxs;

  &--ambassador {
    overflow: unset;
  }
}

.title {
  font-size: $gl-font-size-xs;
  font-weight: 600;
  margin-bottom: $gl-spacer-3xs;
  color: $theme-dark-dk;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  color: $theme-dark;
  font-weight: 500;
  text-align: center;
}

@include desktop {
  .img {
    @include size(72px);
  }

  .title {
    font-size: $gl-font-size-s;
  }

  .subtitle {
    font-size: $gl-font-size-xxs;
  }
}
