
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  @include button-reset();

  background: linear-gradient(180deg, rgba(51, 51, 60, 0.94) 0%, rgba(59, 58, 70, 0.88) 100%);

  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: $gl-spacer-xxs $gl-spacer-m;
  text-transform: uppercase;
  color: $theme-light-lt;
  font-size: 10px;
  white-space: nowrap;
  font-weight: 600;

  @include desktop {
    display: inline-flex;
    border-radius: 0px 0px 12px 12px;
    padding: 6px $gl-spacer-xs;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 15px;
    z-index: $gl-z-index-pre-max;
    width: auto;
  }

}

.inner {
  flex: 1;
  display: block;
}

.icon {
  color: rgba($theme-light-lt, 0.69);
  margin-left: $gl-spacer-xxs;
}

.expanded .icon {
  transform: rotate(180deg);
}
