
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$img-width-desktop: 118px;
$img-width-mobile: 85px;

// $bg-colors: (
//   calculus: #eff8f6,
//   chemistry: #faf2ea,
//   economics: #e2effc,
//   psychology: #f7ecf8,
//   physics: linear-gradient(0deg, #F1F8E9, #F1F8E9),
//   statistics: #edf1fc,
// );

.container {
  border-radius: 16px;
  display: block;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  padding: $gl-spacer-l;
  color: $clr-gray;
  background-color: #f2f2f2;
  margin: auto;
  height: 216px;

  @include mobile {
    padding: $gl-spacer-m;
    width: 100%;
    height: 200px;
  }

  .static {
    position: static;
  }
  // @each $topic, $color in $bg-colors {
  //   &--#{$topic} {
  //     background-color: $color;
  //   }
  // }
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-right: $gl-spacer-s;
}

.footer {
  flex: 0 0 auto;
  padding-top: $gl-spacer-s;
}

.topic {
  font-size: 10px;
  line-height: 1.25;
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.name {
  font-size: $gl-font-size-lg;
  line-height: $gl-line-height-sm;
  margin-top: 6px;
  color: $clr-dark-font;
  font-weight: 500;
}

.description {
  font-size: $gl-font-size-xxs;
  line-height: $gl-line-height-base;
  margin-top: 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.skills {
  font-size: $gl-font-size-xxs;
}

.credits {
  font-size: $gl-spacer-xs;
  line-height: 1;
  color: $theme-light-lt;
  background: $theme-gradient-gold;
  padding: 3px 8px 3px 18px;
  border-radius: $gl-radius-base;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.tutor {
  @include size($gl-asset-size-xs);

  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translate(-10px, -50%);
}

.content {
  height: 100%;
  margin-right: $img-width-desktop;

  @include mobile {
    margin-right: $img-width-mobile;
  }
}

.img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: $img-width-desktop;
  display: block;

  @include mobile {
    width: $img-width-mobile;
  }
}

.badge {
  position: absolute;
  bottom: 0;
  right: 0;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 42px 42px;
    border-color: transparent transparent #ff7a5c transparent;

    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__icon {
    opacity: 0.87;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
}

.progress {
  background: rgba($theme-dark-dkr, 0.12);
  border-radius: 2px;
  height: 4px;
  margin-top: $gl-spacer-4xs;
  overflow: hidden;
  position: relative;
  width: 96px;
  visibility: hidden;

  &_visible {
    visibility: visible;
  }

  &__bar {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    background: $theme-secondary-dk;
  }
}
