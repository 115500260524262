
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
}

.card {
  border-radius: 32px;
  padding: 40px 130px 20px 24px;
  position: relative;
  height: 250px;

  @include media(">=md") {
    width: calc(50% - 20px);
  }

  @include media(">=1140px") {
    height: 313px;
    padding: 55px 200px 40px 40px;
  }

  &_connect {
    background: #edf6f4;
  }

  &_sign-up {
    background: #faf2ea;
  }

  &:not(:last-of-type) {
    margin-bottom: 60px;

    @include media(">=md") {
      margin-bottom: initial;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 17px;
  line-height: 125%;
  color: #121212;
  margin-bottom: 16px;
  position: relative;

  @include media(">=1140px") {
    font-size: 20px;
  }

  @include mobile {
    width: 125%;
  }
}

.text {
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.61);
  position: relative;

  @include media(">=1140px") {
    font-size: 14px;
  }
}

.pic {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -24px;
  max-width: 298px;

  @include media(">=1140px") {
    max-width: 470px;
    right: -3px;
    bottom: -33px;
  }
}

.button {
  margin-top: $gl-spacer-m;
  padding-left: $gl-spacer-m;
  padding-right: $gl-spacer-m;
}
