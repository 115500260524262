
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$hero-image-height-sm: 576px;
$hero-image-height-md: 488px;

.container {
  background: $theme-gradient-sage;
  padding: 0 $gl-spacer-xs;
  position: relative;
  overflow: hidden;
  height: $hero-image-height-sm;

  &.documents-bg {
    background: linear-gradient(to bottom, #F4FAF8, #DCECE6);
  }

  &.booster-bg {
    background: #f4f4fe;
  }

  @include media('>=md') {
    height: $hero-image-height-md;
    flex-direction: row;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @include media('>=md') {
      flex-direction: row;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0 $gl-spacer-m;
    width: 100%;
    z-index: $gl-z-index-1;

    @include media('>=md') {
      margin: 0;
      width: 52%;
    }

    &__title {
      font-weight: bold;
      line-height: $gl-line-height-sm;
      margin-bottom: $gl-spacer-s;
      font-size: 22px;

      @include media('>=md') {
        font-size: 32px;
        margin-bottom: $gl-spacer-m;
      }

      @include media('>=lg') {
        font-size: 34px;
      }
    }

    &__signup {
      &:hover {
        background: tint($clr-black, 10);
      }
    }

    &__signup--has-login {
      margin-right: $gl-spacer-xs;
    }

    &__signup--has-hint {
      margin-right: $gl-spacer-l;
    }

    &__signup,
    &__login {
      flex: 1;
      font-weight: 600;
      font-size: $gl-font-size-s;
      padding: $gl-spacer-xs $gl-spacer-l;

      @include media('>=md') {
        font-size: $gl-font-size-base;
        min-width: 160px;
        padding: 15px $gl-spacer-l;
      }
    }
  }
}

.description {
  margin-bottom: $gl-spacer-m;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  @include media(">=xl") {
    flex-direction: row;
    margin-bottom: $gl-spacer-m;
    padding-bottom: $gl-spacer-l;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }

  &__text {
    line-height: 1.5;
    font-size: $gl-font-size-s2;
    text-align: left;
    display: block;
    max-width: 100%;
    color: rgba($theme-dark-dkr, 0.87);
    margin-bottom: $gl-spacer-s;

    @include media(">=xl") {
      padding-right: $gl-spacer-s;
      margin-bottom: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: $gl-spacer-3xs;

    @include phone {
      margin-bottom: 2px;
    }

    &:last-of-type {
      margin: 0;
    }

    &__icon {
      color: $clr-light-sage;
      margin-right: $gl-spacer-xxs;

      @include media('>=md') {
        margin-right: $gl-spacer-s;
      }
    }

    &__text {
      font-size: $gl-font-size-xs;
      color: rgba($theme-dark-dkr, 0.87);

      @include media('<sm') {
        font-size: $gl-font-size-xxs;
      }
    }
  }
}

.hint {
  color: $clr-gray;
  display: flex;
  font-size: $gl-font-size-3xs;
  margin-top: $gl-spacer-xs;
  width: 220px;

  @include media(">=sm") {
    width: 220px;
  }

  @include media(">=md") {
    width: 300px;
  }

  @include media(">=lg") {
    font-size: $gl-font-size-xxs;
  }

  &__asterisk {
    width: 8px;
    margin-right: $gl-spacer-3xs;
  }

  &__link {
    color: $theme-primary;
  }
}



.image__container {
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
  -webkit-box-flex: 1;
  align-items: flex-end;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;
  max-width: 100%;
  position: relative;
  z-index: $gl-z-index-0;

  @include media('>=md') {
    justify-content: center;
  }
}
