
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$item-margin-bottom: $gl-spacer-xl;
.container {
  div.item {
    padding: $gl-spacer-xl 0;
    width: auto;
    box-sizing: border-box;
    margin-right: $gl-spacer-m;



    @include desktop {
      margin-right: 0;

      @at-root {
        .container:not(.loaded) {
          div.item {
            width: calc(25% - #{math.div(3 * $gl-spacer-m, 4)});
            margin-right: 0;

            &--auto-width {
              width: auto;
            }
          }

          div.item + div.item {
            margin-left: $gl-spacer-m;
          }
        }
      }
    }
  }

  @include mobile {
    div.item:last-child {
      margin-right: 0;
    }
  }
}

div.container {
  position: relative;
  margin-top: - $gl-spacer-m;
  margin-bottom: - $gl-spacer-m;
}

// We need to hide shadows of all cards
// that are not currently visible in the viewport
// If the slider is not loaded yet, we show shadows of first
// fours slides (i.e. the ones that are visible before the slider is initialized)
@include desktop {
  div.container.loaded .item:not(.visible) > *,
  div.container.loaded .item.prev > *,
  div.container:not(.loaded) .item:nth-child(n + 5) > * {
    box-shadow: none;
  }
}

div.slider {
  // TODO: This styles should be applied to sliders with shadowed cards only
  // Eg. Compare product browse with profile#uploads
  margin: 0 (- $gl-spacer-xs);
  padding: 0 $gl-spacer-xs;

  @include desktop {
    margin: 0 (- $gl-spacer-m);
    padding: 0 $gl-spacer-m;
  }
}

.btn {
  @include button-reset();
  @include size(40px);

  position: absolute;
  top: 50%;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba($theme-dark-dkr, 0.12);
  background: $theme-light-lt;
  z-index: $gl-z-index-5;
  text-align: center;
  padding: 0;
  margin: 0;
  display: none;

  &--prev {
    left: 0;
    transform: translate(-50%, -50%);
  }

  &--next {
    right: 0;
    transform: translate(50%, -50%);
  }

  @include desktop() {
    display: block;
  }

  &.disabled { display: none; }
}
