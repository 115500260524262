
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  font-family: $gl-font-family-base;
  color: $theme-dark;
  font-weight: 400;

  @include mobile {
    font-size: $gl-font-size-xxs;
  }
}

.item {
  display: inline-block;
  visibility: middle;
  font-size: rem($gl-font-size-xs);

  @at-root {
    & + &::before {
      content: "›";
      display: inline-block;
      padding: 0 rem(8px);
      color: rgba($theme-dark-dkr, 0.3);
    }
  }

  @include phone {
    padding: 4px 0;
  }
}

.link {
  @include button-reset();
  font-weight: 500;
  font-size: rem($gl-font-size-xs);

  &:not(.default) {
    color: $theme-dark-dk;
    &:hover {
      text-decoration: underline;
    }
  }
}

.count {
  font-family: $gl-font-family-base;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: $clr-blue-green;
  background: $clr-blue-green-lt;
  display: inline-block;
  height: 18px;
  border-radius: 16px;
  margin-left: $gl-spacer-xxs;
  padding: 4px 10px;
}
