
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$hero-image-height-sm: 600px;
$hero-image-height-md: 488px;
$important: config(important);

.hero {
  height: $hero-image-height-sm;

  @include media(">=md") {
    height: $hero-image-height-md;
  }
}

.hero-content {
  @include media("<md") {
    margin-top: 80px $important;
  }
}

.image__container {
  @include media("<md") {
    justify-content: center;
  }
}

.hero-image {
  max-width: none;

  @include media("<md") {
    height: 65vw;
    max-height: 280px;
    transform: translateX(-15px);
  }

  @include media(">=md") {
    bottom: 0;
    height: 260px;
    max-height: 412px;
    position: absolute;
    transform: none;
    right: -80px;
  }

  @include media(">=lg") {
    height: 28vw;
    right: 0;
  }

  @include media(">=xl") {
    height: 31vw;
  }
}

.button-wrapper {
  .to-top {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 32px;
    border: none;
    color: $clr-dark-font $important;

    &:hover {
      background: none;

      @include media(">=md") {
        background: #f2f2f2;
      }
    }
  }
}
