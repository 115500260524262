
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  background: $clr-blue-green-lt;
  padding: $gl-spacer-l;
  font-size: $gl-font-size-xs;
  font-weight: normal;
  color: $theme-dark-alt;
  text-align: center;
  overflow: hidden;

  @include desktop {
    padding: $gl-spacer-m;
    font-size: $gl-font-size-s2;
    border-radius: 12px;
  }

  a {
    font-weight: 500;
  }
}
