
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  padding: $gl-spacer-l $gl-spacer-m;
  border-radius: 32px;
  font-size: $gl-font-size-xs;
  margin-bottom: $gl-spacer-m;
}

.green {
  background: #edf6f6;
}

.blue {
  background: #f1f4ff
}

.yellow {
  background: #faf2ea;
  margin-bottom: 0;
}

.description {
  margin-bottom: $gl-spacer-m;
  color: rgba(0, 0, 0, 0.61);
}

.more {
  @include button-reset();

  font-weight: 500;
  text-decoration: underline;
  font-size: $gl-font-size-xxs;
  cursor: pointer;
  color: inherit;
}

.info {
  margin-bottom: 40px;
}


.title {
  font-weight: bold;
  font-size: $gl-font-size-lg;
  color: $clr-dark-font;
  margin-bottom: 8px;
}

.details {
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  color: $clr-dark-font;
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button {
  @include button-reset();
  color: rgba($theme-dark-dkr, 0.25);
  padding-bottom: $gl-spacer-xs;
  border-bottom: 1px solid rgba($theme-dark-dkr, 0.065);
  display: flex;
  flex: 0 0 calc(100% - 8px);
  justify-content: space-between;
  align-items: center;
  font-size: $gl-font-size-xxs;

  &:hover {
    border-color: rgba(0,0,0,0.54);
  }

  margin-top: $gl-spacer-m;

  &__label {
    color: $clr-dark-font;
    margin-left: $gl-spacer-xxs;
    font-weight: 500;
  }

  &__arrow {
    margin-left: auto;
  }
}


@include desktop {
  .container {
    padding: 40px;
    margin-bottom: 40px;

    &:not(.vertical) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .info {
        flex: 0 0 40%;
        padding-right: 40px;
      }
    }
  }


  .title {
    margin-bottom: $gl-spacer-xs;
  }

  .description {
    margin-bottom: $gl-spacer-s;
  }

  .more {
    font-size: $gl-font-size-xs;
  }

  .info {
    margin-bottom: 0;
  }


  .vertical {
    font-size: $gl-font-size-s;
    display: flex;
    flex-direction: column;

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .description {
      margin-bottom: 28px;
    }
  }

  .button {
    font-size: $gl-font-size-xs;
    flex: 0 0 calc(100% - 16px);
    padding-bottom: $gl-spacer-s;
  }
}
