
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$active-color: #00BE9F;

button.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include desktop {
    width: 140px;
  }
}

.toggler {
  background: none;
  border: none;
  cursor: pointer;
  line-height: $gl-line-height-xs;
  outline: none;
  padding: 0;
  color: rgba($theme-dark-dkr, 0.54);

  &:hover, &:focus {
    background-color: transparent;
    border-color: transparent;
    color: rgba($theme-dark-dkr, 0.54);
  }

  &--active {
    color: $active-color;

    &:hover, &:focus {
      color: $active-color;
    }
  }
  .toggler_icon {
    fill: $theme-light-lt;
  }
  .toggler_active_icon {
    color: $active-color;
  }
}



div.tooltip[x-placement^="top"] {
  margin-bottom: 2px;

  .tooltip__arrow {
    bottom: - 4px;
    left: calc(50% - 4px);
  }
}
