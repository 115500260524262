
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.wrapper {
  display: flex;
  align-items: center;
}

.photos {
  display: flex;
  align-items: center;
}

.frame {
  width: 20px;
  height: 28px;
  overflow: visible;
}

.photo {
  min-width: 28px;
  min-height: 28px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid white;
}

.indicator {
  margin-left: 24px;
  margin-right: 12px;
  width: 7px;
  height: 7px;
  background: #00be9f;
  box-shadow: 0px 0px 10px #00be9f;
  border-radius: 50%;
}

.text {
  font-size: 13px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #121212;
}
