
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$maxWidth: 1200px + $gl-grid-gap-base;

.toggle-container {
  background: $theme-light-lt;
  display: flex;
  margin: 0 auto;

  @include make-container;
  @include make-container-max-widths;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include mobile {
    padding: 0;
    max-width: 100%;
    overflow: auto;
  }

  &--bordered {
    border-top: 1px solid #E7EBEA;
    background: $theme-light-lt;
  }
}

.toggle {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: $gl-spacer-3xs 0 14px;
  // border-bottom: 1px solid rgba($theme-dark-dkr, 0.065);

  @include desktop {
    justify-content: flex-start;
    width: 100%;
  }

  @include phone {
    padding: $gl-spacer-xs $gl-spacer-m $gl-spacer-s;
  }
}

.item {
  font-size: $gl-font-size-s;
  line-height: $gl-line-height-sm;
  position: relative;
  white-space: nowrap;

  & + & {
    margin-left: $gl-spacer-l;

    @include mobile {
      margin-left: $gl-spacer-m;
    }
  }

  &__link {
    color: $clr-gray;
    vertical-align: baseline;
    font-weight: 500;

    &:hover {
      color: $theme-dark-dkr;
    }

    @include mobile {
      &.logged-in {
        font-size: $gl-font-size-s;
      }
    }
  }

  &__underline {
    background: $theme-primary;
    border-radius: 100px;
    bottom: -6px;
    height: 3px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    width: 12px;
  }

  &--active &__link {
    color: $theme-dark-dkr;
    font-weight: 600;
    font-size: $gl-font-size-s;


    @include mobile {
      &.logged-in {
        font-size: $gl-font-size-s;
      }
    }
  }
}
