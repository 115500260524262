
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: $gl-spacer-s;

  @include desktop {
    margin-bottom: 40px;
  }
}

.bordered {
  border-bottom: 1px solid $theme-light-dk;
  padding-bottom: $gl-spacer-s;

  @include desktop {
    margin-bottom: $gl-spacer-m;
  }
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: $gl-font-size-h5;

  @include mobile {
    font-size: $gl-font-size-s2;
  }
}

.subtitle {
  color: $clr-gray;
  font-size: $gl-font-size-s2;
  line-height: $gl-line-height-sm;

  @include mobile {
    font-size: $gl-font-size-xs;
  }

  &__inner {
    & + &::before {
      display: inline-block;
      content: '|';
      color: rgba(0,0,0,0.12);
      margin: 0 $gl-spacer-xs;
    }
  }
}

* + .subtitle {
  margin-top: $gl-spacer-3xs;

  @include desktop {
    margin-top: $gl-spacer-xs;
  }
}
