
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
// Header banner (e.g. final exams)

.container {
  background: $theme-primary-dk;
  padding: $gl-spacer-xs 0;
  font-size: $gl-font-size-s;
  display: block;

  @include plain-hover-focus {
    color: $theme-light-lt;
    text-decoration: none;
  }
}

.title {
  font-size: $gl-font-size-s;
  display: inline;
  vertical-align: middle;
  margin-right: 5px;
  font-weight: 500;

  @include desktop {
    font-size: $gl-font-size-base;
  }
}

.btn {
  padding: 4px 10px;
  font-size: $gl-font-size-s;
}

.icon {
  margin-right: -5px;
}


// THEMES

.alert {
  background: $theme-alert;

  .btn {
    @include button-variant(
      $theme-light-lt,
      $theme-alert,
      $theme-light-lt
    );
  }
}
