
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  text-align: center;

  button.btn {
    padding: 8px;
    font-size: $gl-font-size-xs;
    margin-top: $gl-spacer-xs;
    width: 108px;
    height: 32px;
  }
}
