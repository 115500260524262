
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
* + .container {
  margin-top: $gl-spacer-l;

  @include desktop {
    margin-top: $gl-spacer-xl;
  }
}

