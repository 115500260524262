
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.c-overlay-banner {
  $banner-shadow: $gl-box-shadow-m-x;
  $banner-bg: $theme-light-lt;
  $banner-font-size-mobile: rem($gl-font-size-xxs);

  // Spacing
  $banner-padding-y: rem($gl-spacer-m);
  $banner-padding-y-mobile: rem($gl-spacer-s);

  padding: $banner-padding-y 0;
  text-align: center;
  background: $banner-bg;
  box-shadow: $banner-shadow;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $gl-z-index-pre-max;

  @include mobile {
    font-size: $banner-font-size-mobile;
    padding: $banner-padding-y-mobile 0;
  }
}

.c-overlay-banner-inner {
  position: relative;
}

.c-overlay-banner-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0.45;

  &:hover {
    opacity: 1;
  }
}
