
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.modal {
  height: 449px;
  max-height: 95%;

  @include phone {
    max-height: none;
  }
}

.modal-inner {
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $gl-spacer-s;

  @include phone {
    padding: 0;
  }

  // The following to be nested to solve conflict with oc-core-component styles.
  .close {
    @include button-reset();

    color: $theme-dark-dk;
    cursor: pointer;
    display: block;
    margin-left: auto;
    opacity: 0.4;

    @include phone {
      left: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 1;
    }
  }

  .item {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: $gl-spacer-xxs 0;

    @include phone {
      padding: $gl-spacer-xxs $gl-spacer-m;
    }
  }

  .name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name-text {
    color: $theme-dark-dkr;
    font-size: $gl-font-size-s;
    font-weight: 600;
  }

  .avatar {
    @include size(40px);
  }

  .avatar-col {
    @include size(40px);

    margin-right: $gl-spacer-xs;
  }

  .avatar-container-amp {
    @include size(40px);

    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: $gl-spacer-xs;
    overflow: hidden;
  }
}

.header {
  position: relative;

  @include phone {
    padding: 18px 0;
    text-align: center;
  }
}

.title {
  font-size: $gl-font-size-lg;
  line-height: 1.25;
  margin: 0;
  padding: 0 $gl-spacer-s $gl-spacer-s;

  @include phone {
    line-height: 1.19;
    padding: 0 $gl-spacer-xl;
  }
}

.list {
  border-top: 1px solid rgba($theme-dark-dkr, 0.065);
  list-style: none;
  margin: 0 $gl-spacer-s;
  overflow: auto;
  padding: $gl-spacer-xxs 0;

  @include phone {
    margin: 0;
    padding: $gl-spacer-s 0;
  }
}
