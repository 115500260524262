
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.title {
  font-size: 22px;
  line-height: 125%;
  color: #121212;
  font-weight: bold;
  margin-bottom: 4px;

  @include mobile {
    font-size: 20px;
    margin-bottom: $gl-spacer-xs;
  }
}

.subtitle {
  font-size: $gl-font-size-s;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.61);
  margin-bottom: 24px;

  @include media(">=1140px") {
    margin-bottom: 0;
    font-size: $gl-font-size-s2;
  }
}

.link {
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #121212;
}

.top {
  margin-bottom: 40px;

  @include mobile {
    margin-bottom: $gl-spacer-m;    
  }

  @include media(">=1140px") {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
